(function () {
  'use strict';

  /* @ngdoc object
   * @name rcrFrontend
   * @description
   *
   */
  angular
    .module('rcrFrontend', [
      'ngAria',
	    'ngMessages',
	    'ngAnimate',
	    'ngStorage',
      'ui.bootstrap',
	    'ui.router',
	    'lbServices',
      'home',
      'login',
      'navigation',
      'footer',
      'form',
      'forgotPassword',
      'user',
      'register',
      'members',
      'alerts',
      'messages',
      'pmf',
      'modal',
	  'ngSanitize',
	  'ngCsv'
    ])
	.controller('AppCtrl', AppCtrl)
    .config(function ($provide, $locationProvider, $httpProvider, LoopBackResourceProvider ) {
          //$locationProvider.html5Mode(true).hashPrefix('#!');

          //$locationProvider.html5Mode(true);


          // Use a custom auth header instead of the default 'Authorization'
          LoopBackResourceProvider.setAuthHeader('X-Access-Token');
          if (
              document.location.hostname.substring(0, 7) === '0.0.0.0' ||
              document.location.hostname.substring(0, 3) === '10.' ||
              document.location.hostname.substring(0, 8) === '192.168.' ||
              document.location.hostname.substring(0, 9) === 'localhost'
          ){
              LoopBackResourceProvider.setUrlBase('http://localhost:8000/api');
              $provide.value('system','local');
              $provide.value('api', 'LOCAL-');
              $provide.value('apiUrl', 'http://localhost:8000/api');
          }else if (
              document.location.hostname.substring(0, 10) === 'rcr.wraps.' ||
              document.location.hostname.substring(0, 21) === 'www.rcr.bupa.wraps.io'||
              document.location.hostname.substring(0, 17) === 'rcr.bupa.wraps.io'
          ){
              if (document.location.protocol === 'http' ) {
                  document.location.href = document.location.href.replace('http', 'https');
              }
              LoopBackResourceProvider.setUrlBase('https://api.rcr.bupa.wraps.io/api');
              $provide.value('system','live');
              $provide.value('api', '');
              $provide.value('apiUrl', 'https://api.rcr.bupa.wraps.io/api');
          }/*else {
              // Change the URL to access the LoopBack REST API server
              LoopBackResourceProvider.setUrlBase('https://api.demo.bupa.wraps.io/api');
              $provide.value('system','demo');
              $provide.value('api', 'DEMO-');
              $provide.value('apiUrl', 'https://api.demo.bupa.wraps.io/api');
          }*/




          $httpProvider.interceptors.push(function($q, $location, LoopBackAuth) {
              return {
                  responseError: function(rejection) {
                      if (rejection.status == 401) {
                          // Clearing the loopback values from client browser for safe logout...
                          LoopBackAuth.clearUser();
                          LoopBackAuth.clearStorage();
                          $location.nextAfterLogin = $location.path();
                          $location.path('/login');
                      }
                      return $q.reject(rejection);
                  }
              };
          });

      });
	function AppCtrl($scope, $location, $rootScope, $localStorage, RCRUsers) {

		var vm = this;
		vm.ctrlName = 'AppCtrl';
		$scope.$storage = $localStorage;

		$scope.$watch(function(){
			return $localStorage.loggedInUser;
		}, function (value){
			if (!value.emailVerified){
				$location.path('/login');
			}
		});


		$rootScope.url = $location.path();
		if ($rootScope.url == '/login' || $rootScope.url == '/forgot-password'){
			$rootScope.bodyclass = 'landing black';
		}else{
			$rootScope.bodyclass = '';
		}
		$rootScope.$watch(function(){
		   return $location.path();
		}, function(value){
		  if (value == '/login' || value == '/forgot-password'){
				$rootScope.bodyclass = 'landing black';
			}else if(value.includes('register')){
				$rootScope.bodyclass = '';
			}else{
				$rootScope.bodyclass = '';
				if (!RCRUsers.isAuthenticated()) {
					$location.path('/login');
				}
			}
			
		});
		

	}
}());
